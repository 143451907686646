import React, { Component } from 'react';

import './loading.css';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <section className='loading-main'>
                <div className='container'>
                    <div className='loading-container'>
                        <div className="full-circle"></div>
                        <div id="half-left" className="two-quarters-circle"></div>
                        <div id="half-right" className="two-quarters-circle"></div>
                        <div className="three-quarters-circle"></div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Loading;