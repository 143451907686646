import { takeLatest, call, put, throttle, select } from 'redux-saga/effects';

import CREATING_COUPON_CONSTANTS from './constants';
import LOGIN_CONSTANTS from '../login/constants';
import * as api from '../../api';

import { changeListConfig } from '../coupon/sagas';

function* requestCreateCoupon(action) {
	const informations = action.payload;
	
    yield put({type: CREATING_COUPON_CONSTANTS.LOADING, payload: true});

	try {
        yield call(api.createCoupon, informations);

        yield put({type: CREATING_COUPON_CONSTANTS.REQUEST_SUCCESS});

        yield call(changeListConfig, { payload: [ {key: 'currentPage', value: 1} ] })
	} catch(error) {
		let message;

		message = api.commonErrorHandler(error);
		if(message === undefined) {
			switch(error.response.status) {
				case 400:
					message = 'Parâmetros inválidos, verifique-os.';
                    break;
                    
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

				case 403:
					message = 'Permissão negada.';
					break;

				case 409:
					message = 'Conflito. O código do cupom já existe';
					break;

				default:
					message = 'Erro na criação do cupom.';
					break;
			}
		}
		
		yield put({type: CREATING_COUPON_CONSTANTS.REQUEST_FAILURE, payload: true});
		yield put({type: CREATING_COUPON_CONSTANTS.SHOW_MESSAGE, payload: message});
	}

	yield put({type: CREATING_COUPON_CONSTANTS.LOADING, payload: false});
}

function* requestEditCoupon(action) {
	const informations = action.payload;

	yield put({type: CREATING_COUPON_CONSTANTS.LOADING, payload: true});

	try {
		yield call(api.editCoupon, informations);

        yield put({type: CREATING_COUPON_CONSTANTS.REQUEST_SUCCESS});
        yield call(changeListConfig, { payload: [ {key: 'currentPage', value: 1} ] });
	} catch(error) {
		let message = api.commonErrorHandler(error);
		if(message === undefined) {
			switch(error.response.status) {
				case 400:
					message = 'Parâmetros inválidos';
                    break;

                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

				case 403:
					message = 'Permissão negada.';
					break;

				case 404:
					message = 'Cupom não encontrado';
					break;

				case 409:
					message = 'Conflito. Código do cupom já existe';
					break;

				case 412:
					message = 'Os dados não podem ser alterados pois o cupom já está em uso';
					break;

				default:
					message = 'Erro na edição do cupom';
					break;
			}
		}

		yield put({type: CREATING_COUPON_CONSTANTS.REQUEST_FAILURE, payload: true});
		yield put({type: CREATING_COUPON_CONSTANTS.SHOW_MESSAGE, payload: message});
	}

	yield put({type: CREATING_COUPON_CONSTANTS.LOADING, payload: false});
}

function* requestEmailList(action) {
    const email = action.payload;

    try {
        const emailList = yield call(api.getEmailList, { keyword: email });
        yield put({ type: CREATING_COUPON_CONSTANTS.SET_EMAIL_LIST, payload: emailList });
    } catch(error) {
        let message;

		message = api.commonErrorHandler(error);
		if(message === undefined) {
			switch(error.response.status) {
				case 400:
					message = 'Parâmetros inválidos, verifique-os.';
                    break;
                    
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

				default:
					message = 'Erro na busca por emails.';
					break;
			}
		}
		
		yield put({type: CREATING_COUPON_CONSTANTS.REQUEST_FAILURE, payload: true});
		yield put({type: CREATING_COUPON_CONSTANTS.SHOW_MESSAGE, payload: message});
    }
}

const THROTTLE_INTERVAL = 500;

export default [
	takeLatest(CREATING_COUPON_CONSTANTS.REQUEST_CREATE_COUPON, requestCreateCoupon),
    takeLatest(CREATING_COUPON_CONSTANTS.REQUEST_EDIT_COUPON, requestEditCoupon),
    throttle(THROTTLE_INTERVAL, CREATING_COUPON_CONSTANTS.REQUEST_EMAIL_LIST, requestEmailList),
];