import axios from "axios";

const urls = {
  dev: 'https://dev.apipagamento.scisapp.com',
  prod: "https://apipagamento.scisapp.com"
};

const api = {
  url: `${urls[process.env.REACT_APP_STAGE]}`,
  userInfo: {}
};

function getHeaders() {
  const { access_token = "" } = api.userInfo;
  return {
    Accept: "application/json",
    Authorization: `Bearer ${access_token}`
  };
}

function requestRefresh() {
  const url = `${api.url}/auth/token`;

  const headers = {
    Accept: "application/json"
  };

  const params = {
    grant_type: "refresh_token",
    refresh_token: api.userInfo.refresh_token
  };

  const promise = axios.post(url, params, { headers });
  return promise.then(response => {
    api.userInfo = response.data;
    return true;
  });
}

function axiosPost(relativeUrl, params) {
  const headers = getHeaders();
  const promise = axios.post(`${api.url}/${relativeUrl}`, params, { headers });

  return promise
    .then(result => {
      return result.data;
    })
    .catch(error => {
      switch (error.response.status) {
        default:
          throw error;
      }
    });
}

function axiosGet(relativeUrl, params) {
  const headers = getHeaders();
  const url = `${api.url}/${relativeUrl}`;
  const promise = axios.get(`${url}`, { headers, params });

  return promise
    .then(result => {
      return result.data;
    })
    .catch(error => {
      switch (error.response.status) {
        default:
          throw error;
      }
    });
}

export function commonErrorHandler(error) {
  const { response, request } = error;

  if (!request || !request.status) {
    return "Erro de conexão";
  }

  if (response === undefined) {
    return "Sem descrição";
  }

  if (response.status === 504) {
    return "Tente novamente";
  }

  if (response.status >= 500) {
    return "Serviço indisponível, tente novamente mais tarde";
  }
}

export function login(info) {
  const { email, password } = info;

  const tokenUrl = `${api.url}/auth/token`;
  const params = {
    grant_type: "password",
    username: email,
    password,
    scope: "admin"
  };

  const promise = axios.post(tokenUrl, params);

  return promise.then(result => {
    api.userInfo = result.data;
    return result.data;
  });
}

export function logout() {
  api.userInfo = {};
}

export function getPlansList() {
  const relativeUrl = "plan/list";

  return axiosGet(relativeUrl);
}

export function getBranchList() {
  const relativeUrl = "branch/list";

  return axiosGet(relativeUrl);
}

export function getPlan(couponId) {
  const relativeUrl = `plan/get/${couponId}`;

  return axiosGet(`${relativeUrl}`);
}

/**
 *
 * @param {{ page:number, pageSize:number, planId:number, branchId:number }} properties
 */
export function getCouponsList(properties) {
  let relativeUrl = "coupon/list";

  return axiosGet(relativeUrl, properties);
}

export function getCoupon(couponId) {
  const relativeUrl = `coupon/get/${couponId}`;

  return axiosGet(relativeUrl);
}

export function createCoupon(informations) {
  const relativeUrl = "coupon/create";

  return axiosPost(relativeUrl, informations);
}

export function editCoupon(informations) {
  const relativeUrl = `coupon/edit/${informations.id}`;

  return axiosPost(relativeUrl, informations);
}

/**
 *
 * @param {{ page:number, pageSize:number}} params
 */
export function getPaymentList(params) {
  const relativeUrl = "payment/list";

  return axiosGet(relativeUrl, params);
}

export function getPayment(id) {
  const relativeUrl = `payment/get/${id}`;

  return axiosGet(relativeUrl);
}

export function getEmailList(params) {
  const relativeUrl = `user/search`;

  return axiosGet(relativeUrl, params);
}

export function getIndividualCoupons(params) {
  const relativeUrl = `coupon/list/custom`;

  return axiosGet(relativeUrl, params);
}
