import moment from "moment";

export function fixDate(dateString, format = 'DD/MM/YYYY') {
	moment.locale('pt-br');

	if(dateString === null) {
		return null;
	}

	const tmp = moment.utc(dateString).toDate();
	const formatted = moment(tmp).local().format(format);

	return `${formatted}`;
}

/**
 * 
 * @param {Date} date 
 */
export function dateToUtc(date) {
	moment.locale('pt-br');
	const tmp = moment.utc(date).toDate();
	return moment(tmp).local();
}

/**
 * 
 * @param {Date} date 
 */
export function verifyExpiredDate(date) {
    const test = date.getTime();
    const now = moment.now();

    return now > test;
}