import { all } from 'redux-saga/effects';

import Data from './saga';

import loginSagas from '../containers/login/sagas';
import couponSagas from '../containers/coupon/sagas';
import couponModalSagas from '../containers/coupon-modal/sagas';
import plansSagas from '../containers/plans/sagas';
import financialSagas from '../containers/financial-statement/sagas';

export default function* RootSaga() {
    yield all([
		Data(),
		...loginSagas,
		...couponSagas,
		...couponModalSagas,
		...plansSagas,
		...financialSagas
    ]);
}