import PLANS_CONSTANTS from './constants';

const requestPlansList = () => ({
	type: PLANS_CONSTANTS.REQUEST_PLAN_LIST,
});

const requestPlan = (id) => ({
	type: PLANS_CONSTANTS.REQUEST_PLAN,
	payload: id
});

const setPlansList = (list) => {
    let tmp = [];
    for(const element of list) {
        tmp[element.id] = element;
    }

    return {
        type: PLANS_CONSTANTS.SET_PLAN_LIST,
        payload: { common: list, hash: tmp}
    }
}

export default {
	requestPlansList,
    requestPlan,
    setPlansList,
};