import COUPON_CONSTANTS from './constants';

const DEFAULT_STATE = {
    
    couponsList: [],
    couponsHashList: [],
	couponCreationFailure: false,
    couponCreationSuccess: false,
    branchList: [],
    branchHashList: [],
    modal_info: {},
    loading: false,
    loadingModal: false,
    currentPage: 1,
    totalPages: 1,
    pageSize: 20,
    selectedBranchId: undefined,
    selectedPlanId: undefined,
    filterValue: '',
    searching: false,
};

const reducer = (state = {...DEFAULT_STATE}, action) => {
    const { type, payload } = action;


	switch(type) {

		case COUPON_CONSTANTS.LOADING:
            return {...state, loading: payload};

        case COUPON_CONSTANTS.LOADING_MODAL:
            return { ...state, loadingModal: payload };

		case COUPON_CONSTANTS.SET_COUPON_LIST:
			return {...state, couponsList: payload.common, couponsHashList: payload.hash};

		case COUPON_CONSTANTS.SET_COUPON_CREATION_SUCCESS:
			return {...state, couponCreatedSuccess: payload};

		case COUPON_CONSTANTS.SET_COUPON_CREATION_FAILURE:
			return {...state, couponCreationFailure: payload};

        case COUPON_CONSTANTS.SET_BRANCH_LIST:
            return {...state, branchList: payload.common, branchHashList: payload.hash};

        case COUPON_CONSTANTS.SET_TOTAL_PAGES:
            return { ...state, totalPages: payload };

        case COUPON_CONSTANTS.SET_CURRENT_PAGE:
            return { ...state, currentPage: payload };

        case COUPON_CONSTANTS.SET_CONFIG:
            return { ...state, [payload.key]: payload.value};

        case COUPON_CONSTANTS.SET_MODAL_INFO:
            return { ...state, modal_info: payload };

        case COUPON_CONSTANTS.UPDATE_FILTER_VALUE:
            return { ...state, filterValue: payload };

        case COUPON_CONSTANTS.SEARCHING:
            return { ...state, searching: payload };

		default:
			return state;
	}
};

export default reducer;