import PLANS_CONSTANTS from './constants';

const DEFAULT_STATE = {
    plansList: [],
    plansHashList: [],
	planCreationFailure: false,
	planCreationSuccess: false,
	loading: false,
	errorMessage: ''
}

const reducer = (state = {...DEFAULT_STATE}, action) => {
	const { type, payload } = action;

	switch(type) {

		case PLANS_CONSTANTS.LOADING:
			return {...state, loading: payload};

		case PLANS_CONSTANTS.SET_PLAN_LIST:
			return {...state, plansList: payload.common, plansHashList: payload.hash};

		default:
			return state;
	}
}

export default reducer;