import * as api from '../../api';
import { takeLatest, call, put } from "redux-saga/effects";

import LOGIN_CONSTANTS from './constants';

const defaultLoginAction = {
	type: '',
	payload: {
		email: '',
		password: ''
	}
}

function* requestLogin(action = {...defaultLoginAction}) {
	const { email, password } = action.payload;
	let loginSuccess = false;

	try {
		yield call(api.login, {email, password});
        loginSuccess = true;
        yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: false, action: null }});
	} catch(error) {
		let errorMessage = yield call(api.commonErrorHandler, error);

		if(errorMessage === undefined) {
			const errorStatus = error.response.status;

			switch(errorStatus) {
				case 401:
                    errorMessage = 'email ou senha inválidos';
					break;
	
				case 504:
					errorMessage = 'tente novamente';
					break;
	
				default:
					errorMessage = 'sem mensagem de erro';
					break;
			}
		}

		yield put({type: LOGIN_CONSTANTS.SET_ERROR_MESSAGE, payload: errorMessage});
	}
	yield put({type: LOGIN_CONSTANTS.SET_LOGIN_SUCCESS, payload: loginSuccess});
}

function* logout() {
	yield call(api.logout);
	yield put({type: LOGIN_CONSTANTS.CLEAN});
}

export default [
	takeLatest(LOGIN_CONSTANTS.REQUEST_LOGIN, requestLogin),
	takeLatest(LOGIN_CONSTANTS.LOGOUT, logout)
];