import COUPON_CONSTANTS from './constants';

const requestCouponsList = (obj) => ({
    type: COUPON_CONSTANTS.REQUEST_COUPON_LIST,
    payload: obj
});

const requestIndividualCoupons = (obj) => ({
    type: COUPON_CONSTANTS.REQUEST_INDIVIDUAL_COUPONS,
    payload: obj
});

const requestCoupon = (id) => ({
	type: COUPON_CONSTANTS.REQUEST_COUPON,
	payload: id
});

const setCouponsList = (list) => {
    let tmp = [];
    for(const element of list) {
        tmp[element.id] = element;
    }

    return {
        type: COUPON_CONSTANTS.SET_COUPON_LIST,
        payload: {common: list, hash: tmp}
    }
}

const setBranchList = (list) => {
    let tmp = [];
    for(const element of list) {
        tmp[element.id] = element;
    }
    
    return {
        type: COUPON_CONSTANTS.SET_BRANCH_LIST,
        payload: {common: list, hash: tmp}
    }
}

const setCurrentPage = (page) => ({
    type: COUPON_CONSTANTS.SET_CURRENT_PAGE,
    payload: page
})

const setConfig = (obj) => ({
    type: COUPON_CONSTANTS.CHANGE_LIST_CONFIG,
    payload: obj
});

const openModal = (modalType, id) => ({
    type: COUPON_CONSTANTS.OPEN_MODAL,
    payload: { modalType, id }
});

const updateFilterValue = (value) => ({
    type: COUPON_CONSTANTS.UPDATE_FILTER_VALUE,
    payload: value
})

export default {
    openModal,
	requestCouponsList,
    requestCoupon,
    requestIndividualCoupons,
    setCouponsList,
    setBranchList,
    setCurrentPage,
    setConfig,
    updateFilterValue
};