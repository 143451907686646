import FINANCIAL_CONSTANTS from './constants';

const DEFAULT_STATE = {
	paymentList: [],
    loading: false,
    detailsOpen: false,
    paymentDetails: null,
    startDate: undefined,
    endDate: undefined,
    currentPage: 1,
    totalPages: undefined
};

const reducer = (state = {...DEFAULT_STATE}, action) => {
	const { type, payload } = action;

	switch(type) {

		case FINANCIAL_CONSTANTS.LOADING:
			return {...state, loading: payload};

		case FINANCIAL_CONSTANTS.SET_PAYMENT_LIST:
            return {...state, paymentList: payload};
            
        case FINANCIAL_CONSTANTS.SET_MODAL_OPEN:
            return { ...state, detailsOpen: payload };

        case FINANCIAL_CONSTANTS.SET_PAYMENT_DETAILS:
            return { ...state, paymentDetails: payload };

        case FINANCIAL_CONSTANTS.SET_CONFIG:
            return { ...state, [payload.key]: payload.value };

		default:
			return state;
	}
}

export default reducer;