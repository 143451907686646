import { put, call, takeLatest, takeEvery, select, throttle } from 'redux-saga/effects';

import COUPON_CONSTANTS from './constants';
import LOGIN_CONSTANTS from '../login/constants';
import actions from './actions';
import * as api from '../../api';
import * as utils from '../../utils';

import UTILS_CONSTANTS from '../../utils/constantActions';

function* basicErrorHandling({error, action, messages}) {
    let errorMessage = api.commonErrorHandler(error);

    if(errorMessage === undefined) {
        const errorStatus = error.response.status;

        switch(errorStatus) {
            case 401:
                yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                break;

            case 404:
                errorMessage = messages['404'];
                break;

            default:
                errorMessage = messages.default;
                break;
        }
    }

    yield put({type: COUPON_CONSTANTS.REQUEST_FAILURE, payload: true});
    yield put({type: COUPON_CONSTANTS.SET_ERROR_MESSAGE, payload: errorMessage});
}

export function* requestCouponList(action) {

    yield put({type: COUPON_CONSTANTS.LOADING, payload: true});

	try {
        const { page, pageSize, planId, branchId } = action.payload;
        let result = yield call(api.getCouponsList, { page, pageSize, planId, branchId });

        yield call(requestBranchList);
        const branchList = yield select(state => state.couponReducer.branchList);
        
        let data = [...result.data];

		for(let coupon of data){
			for(let branch of branchList){
				if(branch.id === coupon.branchId){
					coupon["branchName"] = branch.name
					break;
				}
			}
		}

        yield put(actions.setCouponsList(data));
        yield put({type: COUPON_CONSTANTS.SET_TOTAL_PAGES, payload: result.totalPages});
	} catch(error) {
        const messages = {
            default: 'Erro na requisição da lista de cupons'
        }
		yield call(basicErrorHandling, { error, action, messages});
	}

	yield put({type: COUPON_CONSTANTS.LOADING, payload: false});
}

export function* requestCoupon(itemId) {
	try {
        return yield call(api.getCoupon, itemId);
	} catch(error) {
        const messages = {
            default: 'Erro na busca de cupom',
            '404': 'Cupom não encontrado'
        }
		yield call(basicErrorHandling, { error, action: itemId, messages});
	}
}

function* requestBranchList(action) {
    try {
        const branchList = yield call(api.getBranchList);
        yield put(actions.setBranchList(branchList));
    } catch(error) {
        const messages = {
            default: 'Erro na requisição da lista de unidades'
        }
		yield call(basicErrorHandling, { error, action, messages});
	}
}

function* requestIndividualCoupons(action) {
    try {
        const individuals = yield call(api.getIndividualCoupons, action.payload);
        yield put({type: COUPON_CONSTANTS.SET_TOTAL_PAGES, payload: individuals.totalPages});
        yield put(actions.setCouponsList(individuals.data));
    } catch(error) {
        const messages = {
            default: 'Erro na requisição da lista de cupons individuais'
        }
		yield call(basicErrorHandling, { error, action, messages});
    }
}

let changingCouponType = false;
function* changeTabCouponActive(action) {
    if(changingCouponType) {
        return;
    }

    changingCouponType = true;
    yield put({ type: COUPON_CONSTANTS.LOADING, payload: true });

    if(action.data === 'individual') {
        yield call(requestIndividualCoupons, { payload: { page: 1 } });
    } else {
        yield call(requestCouponList, { payload: { page: 1 } });
    }

    yield put({ type: COUPON_CONSTANTS.LOADING, payload: false });
    changingCouponType = false;
}

function* setCurrentPage(action) {
    const individual = yield select(state => state.data.individual);
    if(individual === 'individual') {
        yield call(requestIndividualCoupons, action);
    } else {
        yield call(requestCouponList, action);
    }
}

export function* changeListConfig(action) {
    let downloadList = false;
    for(const item of action.payload) {
        if(item.key === 'selectedPlanId' || item.key === 'selectedBranchId') {
            yield put({ type: COUPON_CONSTANTS.SET_CONFIG, payload: { key: 'currentPage', value: 1 } });
        }
        if(item.key === 'selectedBranchId' || item.key === 'selectedPlanId' || item.key === 'currentPage' || item.key === 'pageSize' || item.key === 'keyword') {
            downloadList = true;
        }
        yield put({ type: COUPON_CONSTANTS.SET_CONFIG, payload: { key: item.key, value: item.value } });
    }

    const state = yield select(state => state);
    const couponReducer = state.couponReducer;
    const couponInformations = { branchId: couponReducer.selectedBranchId, planId: couponReducer.selectedPlanId, page: couponReducer.currentPage, pageSize: couponReducer.pageSize};
    if( couponReducer.keyword  !== ""){
        couponInformations.keyword = couponReducer.keyword 
    }

    if(downloadList) {
        yield call(state.data.tabCoupon === 'individual' ? requestIndividualCoupons : requestCouponList, { payload: couponInformations });
    }
}

function* openModal(action) {
    const { modalType, id } = action.payload;

    yield put({ type: COUPON_CONSTANTS.LOADING_MODAL, payload: true });

    try {

        let modal_info, modalKey;
        yield call(requestBranchList);
        const branchList = yield select(state => state.couponReducer.branchList);

        switch(modalType) {
            case 'edit':
                const coupon = yield call(api.getCoupon, id);

    
                modal_info = {
                    ...coupon,
                    couponId: id
                }
 
                modalKey = 'editModalOpen';
                break;
            case 'create':
                modal_info = {
                    email: null,
                    studentName: null,
                    userId: null,
                    subscriptionPlanId: null,
                    description: null,
                    expirationDate: null,
                    enabled: false,
                    currencyType: 'R$',
                    unit: null,
                    discount: 'R$0.00',
                    code: null,
                    couponId: id
                };
                modalKey = 'createModalOpen';
                break;
            case 'details':
                const couponD = yield call(api.getCoupon, id);

                modal_info = {
                    ...couponD,
                    couponId: id
                }
 
                modalKey = 'detailsModalOpen';
                break;
            default:
                modal_info = null;
                break;
        }

        yield put({ type: COUPON_CONSTANTS.SET_CONFIG, payload: {key: 'modal_info', value: modal_info }});
        yield put({ type: COUPON_CONSTANTS.SET_CONFIG, payload: { key: modalKey, value: true } });
        yield put({ type: COUPON_CONSTANTS.LOADING_MODAL, payload: false });

    } catch(error) {
        console.log('error:', error);
		const messages = {
            default: 'Erro na requisição da lista de cupons'
        }
		yield call(basicErrorHandling, { error, action, messages});
	}
}

function* updateFilterValue(action) {
    const value = action.payload;

    if(value.length < 3 && value.length !== 0) {
        return;
    } 

    yield put({ type: COUPON_CONSTANTS.SEARCHING, payload: true });

    try {

        yield call(changeListConfig, {payload: [ {key: 'keyword', value: value} ]} );

    } catch(error) {
        const messages = {
            default: 'Erro ao requisitar cupons'
        }
        yield call(basicErrorHandling, { error, action, messages });
    }

    yield put({ type: COUPON_CONSTANTS.SEARCHING, payload: false });
}

const THROTTLE_INTERVAL = 500;

export default [
    takeLatest(COUPON_CONSTANTS.REQUEST_BRANCH_LIST, requestBranchList),
	takeLatest(COUPON_CONSTANTS.REQUEST_COUPON_LIST, requestCouponList),
    takeLatest(COUPON_CONSTANTS.REQUEST_COUPON, requestCoupon),
    takeLatest(COUPON_CONSTANTS.REQUEST_INDIVIDUAL_COUPONS, requestIndividualCoupons),
    takeLatest(COUPON_CONSTANTS.SET_CURRENT_PAGE, setCurrentPage),
    takeLatest(COUPON_CONSTANTS.OPEN_MODAL, openModal),
    takeLatest(COUPON_CONSTANTS.CHANGE_LIST_CONFIG, changeListConfig),
    takeEvery(UTILS_CONSTANTS.CHANGE_TAB_COUPON_ACTIVE, changeTabCouponActive),
    throttle(THROTTLE_INTERVAL, COUPON_CONSTANTS.UPDATE_FILTER_VALUE, updateFilterValue),
]