export default {
    
    LOADING: 'COUPONLOADING',
    LOADING_MODAL: 'COUPONLOADINGMODAL',
    
    CHANGE_LIST_CONFIG: 'COUPONCHANGELISTCONFIG',

    OPEN_MODAL: 'COUPONOPENMODAL',

    REQUEST_BRANCH_LIST: 'COUPONREQUESTBRANCHLIST',
	REQUEST_COUPON_LIST: 'COUPONREQUESTCOUPONLIST',
    REQUEST_COUPON: 'COUPONREQUESTCOUPON',
    REQUEST_INDIVIDUAL_COUPONS: 'COUPONMODALREQUESTINDIVIDUALCOUPONS',

    REQUEST_FAILURE: 'COUPONREQUESTFAILURE',
    
    SEARCHING: 'COUPONSEARCHING',

    SET_BRANCH_LIST: 'COUPONSETBRANCHLIST',
    SET_CONFIG: 'COUPONSETCONFIG',
	SET_COUPON_LIST: 'COUPONSETCOUPONLIST',
	SET_COUPON_CREATION_FAILURE: 'COUPONSETCOUPONCREATIONFAILURE',
	SET_COUPON_CREATION_SUCCESS: 'COUPONSETCOUPONCREATIONSUCCESS',
    SET_CURRENT_PAGE: 'COUPONSETCURRENTPAGE',
    SET_ERROR_MESSAGE: 'COUPONSETERRORMESSAGE',
    SET_MODAL_INFO: 'COUPONSETMODALINFO',
    SET_TOTAL_PAGES: 'COUPONSETTOTALPAGES',

    UPDATE_FILTER_VALUE: 'COUPONUPDATEFILTERVALUE'
};