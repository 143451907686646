import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from '../reducers/index';
import rootSaga from '../saga/rootSaga';

import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';

const sagaMiddleware = createSagaMiddleware();

const history = createHistory();

history.listen(location => {
    setTimeout(() => {
        if (location.action === 'POP') {
          return;
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    
      });
});

const historyMiddleware = routerMiddleware(history);

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(historyMiddleware, sagaMiddleware),
);

const store = createStore(
    reducers,
    enhancer
    
);

sagaMiddleware.run(rootSaga);

export default store;

export { history };