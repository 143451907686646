export default {

	LOADING: 'PLANSLOADING',

	REQUEST_PLAN_LIST: 'PLANSREQUESTPLANSLIST',
	REQUEST_PLAN: 'PLANSREQUESTPLAN',

	REQUEST_FAILURE: 'PLANSREQUESTFAILURE',
	REQUEST_SUCCESS: 'PLANSREQUESTSUCCESS',

	SET_ERROR_MESSAGE: 'PLANSSETERRORMESSAGE',
	SET_PLAN_LIST: 'PLANSSETPLANLIST',
}