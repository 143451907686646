import LOGIN_CONSTANTS from './constants';

const INITIAL_STATE = {
	loginSuccess: undefined,
    errorMessage: '',
    retryLogin: { status: false, action: null }
};

const reducer = (state = {...INITIAL_STATE}, action) => {

	const { type, payload } = action;

	switch(type) {

		case LOGIN_CONSTANTS.SET_LOGIN_SUCCESS:
			return {...state, loginSuccess: payload };

		case LOGIN_CONSTANTS.SET_ERROR_MESSAGE:
            return {...state, errorMessage: payload };
            
        case LOGIN_CONSTANTS.SET_RETRY_LOGIN:
            return { ...state, retryLogin: payload };

		case LOGIN_CONSTANTS.CLEAN:
			return {...INITIAL_STATE};

		default:
			return state;
	}

};

export default reducer;