import { put, takeEvery } from "redux-saga/effects";

import actions from "../utils/constantActions";

function* modalDateInfo(action) {
    const { data } = action

    try {
        yield put({ type: actions.MODAL_DATE_IS_OPEN, data: data })
    } catch (e) {
        console.log(e)
    }
}

function* openModalDate(action) {
    yield put({ type: actions.MODAL_DATE_IS_OPEN, data: true })
}


export default function* mySaga() {
    yield takeEvery(actions.ASYNC_MODAL_DATE_IS_OPEN, modalDateInfo);
    yield takeEvery(actions.ASYNC_OPEN_MODAL_DATE, openModalDate);
}