import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as api from '../../api';
import FINANCIAL_CONSTANTS from './constants';
import LOGIN_CONSTANTS from '../login/constants';

function* requestPaymentList(action) {

    yield put({type: FINANCIAL_CONSTANTS.LOADING, payload: true});
    
    const { startDate, endDate, currentPage, searchValue, filterStatus } = yield select(state => state.financialReducer);

	try {
		const result = yield call(api.getPaymentList, { startDate, endDate, page: currentPage, keyword: searchValue, status: filterStatus });
        yield put({type: FINANCIAL_CONSTANTS.SET_PAYMENT_LIST, payload: result.data});
        yield put({ type: FINANCIAL_CONSTANTS.SET_CONFIG, payload: { key: 'totalPages', value: result.totalPages } });
	} catch(error) {

        let errorMessage = api.commonErrorHandler(error);

        if(errorMessage === undefined) {
            const errorStatus = error.response.status;
            switch(errorStatus) {
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

                default:
                    break;
            }
        }
	}

	yield put({type: FINANCIAL_CONSTANTS.LOADING, payload: false});
}

function* openDetails(action) {

    try {
        const result = yield call(api.getPayment, action.payload);
        yield put({type: FINANCIAL_CONSTANTS.SET_PAYMENT_DETAILS, payload: result});
        yield put({type: FINANCIAL_CONSTANTS.SET_MODAL_OPEN, payload: true});
    } catch(error) {
        let errorMessage = api.commonErrorHandler(error);

        if(errorMessage === undefined) {
            const errorStatus = error.response.status;
            switch(errorStatus) {
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

                default:
                    break;
            }
        }
    }
}

function* changeConfig(action) {
    try {
        for(const item of action.payload) {
            yield put({type: FINANCIAL_CONSTANTS.SET_CONFIG, payload: { key: item.key, value: item.value }});
        }
        yield call(requestPaymentList);
    } catch(error) {
        let errorMessage = api.commonErrorHandler(error);

        if(!errorMessage) {
            const errorStatus = error.response.status;
            switch(errorStatus) {
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

                default:
                    break;
            }
        }
    }
}

export default [
    takeLatest(FINANCIAL_CONSTANTS.REQUEST_PAYMENT_LIST, requestPaymentList),
    takeLatest(FINANCIAL_CONSTANTS.OPEN_DETAILS, openDetails),
    takeLatest(FINANCIAL_CONSTANTS.CHANGE_CONFIG, changeConfig)
];