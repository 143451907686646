import { call, put, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import PLANS_CONSTANTS from './constants';
import LOGIN_CONSTANTS from '../login/constants';
import actions from './actions';

function* requestPlanList(action) {
	
	yield put({type: PLANS_CONSTANTS.LOADING, payload: true});

	try {
		const result = yield call(api.getPlansList);
		yield put(actions.setPlansList(result));
	} catch(error) {
		let errorMessage = api.commonErrorHandler(error);

		if(errorMessage === undefined) {
			const errorStatus = error.response.status;

			switch(errorStatus) {
				case 504:
					errorMessage = 'Tente novamente';
                    break;
                    
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;
	
				default:
					errorMessage = 'Erro na requisição da lista de planos';
					break;
			}
		}

		yield put({type: PLANS_CONSTANTS.REQUEST_FAILURE, payload: true});
		yield put({type: PLANS_CONSTANTS.SET_ERROR_MESSAGE, payload: errorMessage});
	}

	yield put({type: PLANS_CONSTANTS.LOADING, payload: false});
}

function* requestPlan(action) {
	const itemId = action.payload;

	yield put({type: PLANS_CONSTANTS.LOADING, payload: true});

	try {
		// TODO: use this
		yield call(api.getPlan, itemId);
	} catch(error) {
		let errorMessage = api.commonErrorHandler(error);

		if(errorMessage === undefined) {
			const errorStatus = error.response.status;

			switch(errorStatus) {
                case 401:
                    yield put({type: LOGIN_CONSTANTS.SET_RETRY_LOGIN, payload: { status: true, action }});
                    break;

				case 404:
					errorMessage = 'Plano não encontrado';
					break;
	
				case 504:
					errorMessage = 'Tente novamente';
					break;
	
				default:
					errorMessage = 'Erro na busca do plano';
					break;
			}
		}

		yield put({type: PLANS_CONSTANTS.REQUEST_FAILURE, payload: true});
		yield put({type: PLANS_CONSTANTS.SET_ERROR_MESSAGE, payload: errorMessage});
	}

	yield put({type: PLANS_CONSTANTS.LOADING, payload: false});
}

export default [
	takeLatest(PLANS_CONSTANTS.REQUEST_PLAN_LIST, requestPlanList),
	takeLatest(PLANS_CONSTANTS.REQUEST_PLAN, requestPlan),
];