
import actions from "../utils/constantActions";

const INITIAL_STATE = {
    modalDateInfoIsOpen: false,
    menuManagerActive: 'home',
    modalToReverseIsOpen: false,
    tabCoupon: 'unidades'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case actions.MODAL_DATE_IS_OPEN:
            return { ...state, modalDateInfoIsOpen: action.data }

        case actions.CHANGE_MENU_MANAGER_ACTIVE:
            return { ...state, menuManagerActive: action.data }

        case actions.CHANGE_TAB_COUPON_ACTIVE:
            return { ...state, tabCoupon: action.data }

        default:
            return state;
    }
};
