import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Router, Route, Switch } from 'react-router-dom';

import LoadingPage from './components/loading/loading';

import store, { history } from './utils/store';

function Loading({ error }) {
  if (error) {
    console.error(error);
    return 'Oh no!';
  } else {
    return <LoadingPage/>;
  }
}

const Login = Loadable({
  loader: () => import('./containers/login/login'),
  loading: Loading
});

const Home = Loadable({
  loader: () => import('./containers/home/home'),
  loading: Loading
});

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className='app'>
            <Switch>
              <Route exact path='/' component={Login} />
              <Route exact path='/home' component={Home} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;

