import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux';

import data from "./data";

import loginReducer from '../containers/login/reducer';
import couponReducer from '../containers/coupon/reducer';
import couponModalReducer from '../containers/coupon-modal/reducer';
import plansReducer from '../containers/plans/reducer';
import financialReducer from '../containers/financial-statement/reducer';

export default combineReducers({
	data,
	router: routerReducer,
	loginReducer,
	couponReducer,
	couponModalReducer,
	plansReducer,
	financialReducer
});
