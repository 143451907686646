export default {
	CLEAN: 'COUPONMODALCLEAN',

    LOADING: 'COUPONMODALLOADING',
    LOAD_INFORMATION: 'COUPONMODALLOADINFORMATION',

	SHOW_MESSAGE: 'COUPONMODALSHOWMESSAGE',

	REQUEST_CREATE_COUPON: 'COUPONMODALREQUESTCREATECOUPON',
	REQUEST_EDIT_COUPON: 'COUPONMODALREQUESTEDITCOUPON',
    REQUEST_EMAIL_LIST: 'COUPONMODALREQUESTEMAILLIST',

	REQUEST_FAILURE: 'COUPONMODALREQUESTFAILURE',
	REQUEST_SUCCESS: 'COUPONMODALREQUESTSUCCESS',

    SET_EMAIL_LIST: 'COUPONMODALSETEMAILLIST',
	SET_INFORMATIONS: 'COUPONMODALSETINFORMATIONS',
    SET_PLAN_OPTIONS: 'COUPONMODALSETPLANOPTIONS',
    SET_UNIT_OPTIONS: 'COUPONMODALSETUNITOPTIONS',
};