import COUPON_CREATING_CONSTANTS from './constants';

const DEFAULT_STATE = {
	createFailure: false,
	createSuccess: false,
	showMessage: '',
	informations: {
		subscriptionPlanId: '',
		description: '',
		expirationDate: null,
		enabled: false,
		percentual: false,
		discount: '',
        code: '',
        email: '',
        name: '',
        currencyType: 'R$',
        unit: ''
    },
	loading: false,
    planOptions: [],
    unitOptions: [],
    emailList: [],
    createModalOpen: false,
    editModalOpen: false,
    detailsModalOpen: false
};

const reducer = (state = {...DEFAULT_STATE}, action) => {
	const { type, payload } = action;

	switch(type) {

		case COUPON_CREATING_CONSTANTS.CLEAN:
			return {...DEFAULT_STATE};

		case COUPON_CREATING_CONSTANTS.LOADING:
			return {...state, loading: payload};

		case COUPON_CREATING_CONSTANTS.SHOW_MESSAGE:
			return {...state, showMessage: payload};

		case COUPON_CREATING_CONSTANTS.REQUEST_FAILURE:
			return {...state, success: false, failure: true};

		case COUPON_CREATING_CONSTANTS.REQUEST_SUCCESS:
			return {...state, success: true, failure: false};

        case COUPON_CREATING_CONSTANTS.SET_EMAIL_LIST:
            return { ...state, emailList: payload };

		case COUPON_CREATING_CONSTANTS.SET_INFORMATIONS:
			return {...state, informations: payload};

		case COUPON_CREATING_CONSTANTS.SET_PLAN_OPTIONS:
            return {...state, planOptions: payload};
            
        case COUPON_CREATING_CONSTANTS.SET_UNIT_OPTIONS:
            return {...state, unitOptions: payload};

		default:
			return state;
	}
}

export default reducer;